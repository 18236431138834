import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import store from './store'
import { register } from './serviceWorker'
import ThemeProvider from './Theme/ThemeProvider'
import './index.css'

const Main = () => {
  return (
    <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </React.StrictMode>
    </Provider>
  )
}

ReactDOM.render(<Main />, document.getElementById('root'))

register()
