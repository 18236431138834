/* eslint-disable no-case-declarations */
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import moment from 'moment'
import Summary from '../../components/Info/Summary/Summary'
import { parseQuery, getCookies, getCandleIntervalsMap } from '../../utils/common'
import logo from '../../assets/logo.png'
import { withTheme } from '../../Theme/ThemeProvider'
import {
  COLORS, FONTS, SPACING, theme, ASSETS,
} from '../../Theme'
// import Button from '../../UI/Button'
import CustomHorizontalTab from '../../UI/CustomHorizontalTab'
import { candleIntervals } from '../../utils/consts'
import TechnicalAggregate from '../../components/Info/Technicals/TechnicalAggregate'
import TabView from '../../UI/TabView'
import SampleStrategies from '../../components/Info/Strategies/SampleStrategies'
import StockIcon from '../../UI/StockIcon'
import Flatlist from '../../UI/Flatlist'
import CustomText from '../../UI/CustomText'
import HighlightedText from '../../UI/HighlightedText'
import SegSym from '../../UI/SegSym'
import ResistanceItem from '../../components/Info/Resistance/ResistanceItem'

const baseBrokerMap = {
  zerodha: 'zerodha',
  'angel broking': 'angel',
  angelone: 'angel',
  '5paisa': 'paisa',
  upstox: 'uptsox',
}

const {
  CANDLE_PATTERNS, WEBINAR, BULLISH, BEARISH, INTRADAY_BULLISH, MISCELLANEOUS,
  INTRADAY_BEARISH, RANGE_BREAKOUT, CROSSOVER, TOP_GAINERS, TOP_LOSERS, LogoDark,
  // ALL
} = ASSETS

const renderTypeMap = [
  { title: 'Summary', value: 0, btnText: 'Summary' },
  { title: 'Technicals', value: 1, btnText: 'Technicals' },
  { title: 'Resistance and Support', value: 2, btnText: 'Resistance and Support' },
  { title: 'Strategies', value: 3, btnText: 'Strategies' },
  // { title: 'Scanners', value: 4, btnText: 'Scanners' },
]

const timeFrames = getCandleIntervalsMap(candleIntervals, candleIntervals)

class Info extends Component {
  constructor(props) {
    super(props)
    this.timeRanges = {
      '1 min': 'min',
      '3 mins': '3min',
      '5 mins': '5min',
      '15 mins': '15min',
      '30 mins': '30min',
      Hour: 'hour',
      Day: 'day',
    }
    this.timeRangesSmall = {
      '1m': 'min',
      '3m': '3min',
      '5m': '5min',
      '15m': '15min',
      '30m': '30min',
      '1H': 'hour',
      '1D': 'day',
    }
    this.time = {
      min: 1,
      '3min': 3,
      '5min': 5,
      '10min': 10,
      '15min': 15,
      '30min': 30,
      hour: 60,
      day: 24,
    }
    this.finalTimeRange = this.timeRanges
    const queries = parseQuery(document.location.search)
    const {
      stock = '', user_id = '', broker = 'angel one', ft,
    } = queries
    this.stock = stock || 'NSE:SBIN'
    this.stock = this.stock.replace('-EQ', '')
    this.user_id = user_id || 'TF1576'
    this.broker = unescape(broker)
    this.brokerId = baseBrokerMap[this.broker.toLowerCase()]
    // this.kiteApp = app === 'kite3'
    this.baseUrl = 'https://streak.tech' // this.kiteApp ? 'streak://app.deeplink' : 'https://streak.zerodha.com'
    this.analysisParams = 'utm_source=angelbroking-technicals&utm_medium=angelbroking'
    this.state = {
      active: 'summary',
      activeIndex: 0,
      selectedRange: 'hour'
    }
    ReactGA.initialize('UA-110056397-3', {
      gaOptions: {
        userId: this.user_id
      }
    })
    this.img_tags = {
      'Range Breakout': RANGE_BREAKOUT,
      'Intraday Bearish': INTRADAY_BEARISH,
      'Intraday Bullish': INTRADAY_BULLISH,
      Crossover: CROSSOVER,
      Bullish: BULLISH,
      Bearish: BEARISH,
      'Top Gainers': TOP_GAINERS,
      'Top Losers': TOP_LOSERS,
      'Candle Patterns': CANDLE_PATTERNS,
      'Webinar Scanners': WEBINAR
    }
    this.timeRangeRef = React.createRef()
    this.isDeviceMobile = false
    this.originalTabs = ['Summary', 'Technicals', 'Support and Resistance', 'Strategies', 'Scanner']
    this.availableTabs = this.originalTabs
    this.route = true
    if (navigator.userAgent.toLowerCase().search('mobile') > -1) {
      this.isDeviceMobile = true
      this.availableTabs = this.originalTabs.filter(tab => tab !== 'Scanner')
    }
    if (queries.streak_app) {
      this.route = false
      this.availableTabs = this.originalTabs.filter(tab => tab !== 'Strategies' && tab !== 'Scanner')
    }
    if (ft === 'true') {
      this.availableTabs = ['Summary', 'Technicals', 'Resistance'] // , ,
    }
    this.resistanceOptions = [
      // { name: 'Remove', Icon: DeleteIcon, color: 'red' },
    ]
  }

  componentDidMount = () => {
    const { getAnalysis, subscribePair } = this.props
    const seg_sym = decodeURIComponent(this.stock)
    const segSym = seg_sym.replace(':', '_')
    subscribePair([segSym])
    let defaultFrameKey = 'hour'
    if (this.timeRangeRef.current) {
      const width = this.timeRangeRef.current.clientWidth
      if (width < 460) {
        this.finalTimeRange = this.timeRangesSmall
        defaultFrameKey = '1H'
      }
    }
    let { timeFrame } = getCookies()
    let found = false
    const timeVals = Object.entries(this.finalTimeRange)
    for (let i = 0; i < timeVals.length; i++) {
      if (timeFrame === timeVals[i][1]) {
        timeFrame = timeVals[i][0] // eslint-disable-line
        found = true
        break
      }
    }
    if (!found) {
      timeFrame = defaultFrameKey
    }
    this.setState({ selectedRange: timeFrame || defaultFrameKey }, () => {
      const params = {
        timeFrame: this.finalTimeRange[timeFrame] || defaultFrameKey,
        stock: this.stock,
        user_id: this.user_id,
      }
      this.headers = new Headers()
      this.headers.append('Content-Type', 'application/json')
      getAnalysis(params, this.headers)
      // this.fetchResistance(params.timeFrame)
      // getSamples(params, this.headers)
      // this.getPopularScreens()
    })
    ReactGA.event({
      category: 'angelbroking_mo_tab_change',
      action: 'angelbroking_mo_tab_change',
      label: 'summary',
      value: this.time[timeFrame],
    })
  }

  fetchResistance = (time_frame) => {
    const {
      fetchResistance,
      // clearAnalysis,
    } = this.props

    if (this.stock) {
      const params = {
        time_frame: time_frame || 'hour',
        stocks: [decodeURIComponent(this.stock)],
        user_broker_id: this.user_id, // dummy user id
      }
      // time frame changed or multi stock fetch (page change)
      // clearAnalysis(analysisTabMap.resistance.value)
      this.headers = new Headers()
      this.headers.append('Content-Type', 'application/json')
      fetchResistance(params, this.headers)
    }
  }

  timeChangeHandler = (time) => {
    const {
      selectedRange,
      active
    } = this.state
    const { getAnalysis } = this.props
    if (time !== selectedRange) {
      this.setState({ selectedRange: time }, () => {
        const params = {
          timeFrame: time,
          stock: this.stock,
          user_id: this.user_id,
        }
        getAnalysis(params, this.headers)
        this.fetchResistance(params.timeFrame)
      })
      document.cookie = `timeFrame=${this.finalTimeRange[time]}`
      ReactGA.event({
        category: 'angelbroking_mo_tab_change',
        action: 'angelbroking_mo_tab_chang',
        label: active,
        value: this.time[time],
      })
      ReactGA.pageview(`${active}/${escape(this.stock)}/${time}`)
    }
  }

  activeTabHandler = (tab, index) => {
    const { selectedRange } = this.state
    const { title } = index
    this.setState({ active: title, activeIndex: index.value })
    ReactGA.event({
      category: 'angelbroking_mo_tab_chang',
      action: 'angelbroking_mo_tab_chang',
      label: title,
      value: this.time[selectedRange],
    })
    ReactGA.pageview(`${title}/${escape(this.stock)}/${selectedRange}`)
  }

  strategyBacktests = (url, name, algo_uuid) => {
    ReactGA.event({
      category: 'angelbroking_mo_Backtest',
      action: 'angelbroking_mo_backtest',
      label: name,
      value: algo_uuid
    })
    window.open(url, '_blank')
  }

  createAlgo = () => {
    ReactGA.event({
      category: 'angelbroking_mo_CreateAlgo',
      action: 'angelbroking_mo_createAlgo',
      label: escape(this.stock)
    })
  }

  demoGA = () => {
    ReactGA.event({
      category: 'ab_mo_demo',
      action: 'ab_mo_demo',
      label: 'demo_link_ab'
    })
  }

  getPopularScreens = () => {
    const { getPopularScreens } = this.props
    const params = {
      page_size: 100,
      page_number: 1,
      include_fields: [],
      sort: {
        // last_updated: 'desc',
        priority: 'asc',
      },
      search: {
        sample: [true],
      },
    }
    getPopularScreens(params, this.headers)
  }

  renderSampleBtCard = (item, index) => {
    const {
      styles,
      isDark,
    } = this.props
    const {
      screener_name,
      last_updated,
      time_frame,
      // universe,
      symbol_count = 0,
    } = item
    const len = symbol_count
    const matchText = len > 1 ? 'matches' : 'match'
    const bg = '#EBEFF5'
    const bgDark = 'greyLightBg'
    return (
      <div className={styles.cardContainer} key={index} ref={this.containerRef}>
        <div className={styles.cardHeader}>
          <CustomText weight="semi_bold">{screener_name}</CustomText>
          <HighlightedText
            color="text"
            backgroundColor={isDark ? bgDark : bg}
            text={time_frame}
            iconSize={14}
            styles={{
              padding: `${SPACING.SPACE_2} ${SPACING.SPACE_12}`,
              borderRadius: 4,
            }}
          />
        </div>
        <div className={styles.matches}>
          <CustomText size="small">{moment(last_updated).format('DD/MM/YY hh:mm:ss A')}</CustomText>
          <CustomText
            style={{
              marginLeft: '1rem',
              borderRadius: 25,
              width: 100,
            }}
            color={theme.linkColor}
          >
            {`${len} ${matchText}`}
          </CustomText>
        </div>
        <button
          type="button"
          className={styles.createBtn}
          onClick={() => this.actionItemHandler(item)}
        >
          Scan Now
        </button>
      </div>
    )
  }

  actionItemHandler = (item) => {
    const { screener_uuid } = item
    const currentUrl = document.location.ancestorOrigins[0]
    if(currentUrl !== undefined && currentUrl.includes('streak')) {
      // eslint-disable-next-line no-param-reassign
      this.baseUrl = currentUrl
    }
    if (screener_uuid) {
      window.open(`${this.baseUrl}/scans/popular?id=${screener_uuid}&tech_route=true&${this.analysisParams}&rb=angel one`, '_blank')
    }
  }

  renderActiveTab = () => {
    const {
      analysis,
      samples,
      popularScreens,
      isFetching,
      styles,
      resistance,
      getSamples,
    } = this.props
    const { activeIndex } = this.state
    const {
      selectedRange,
    } = this.state
    const defaultFrameKey = 'hour'
    const timeFrame = this.finalTimeRange[selectedRange] || defaultFrameKey
    const dispName = unescape(this.stock).split(':') || []
    const stock = dispName[1] || ''
    const exchange = dispName[0] || ''
    switch (activeIndex) {
      case 0:
        return (
          <Summary
            analysis={analysis}
            isFetching={isFetching}
            tabChangeHandler={() => this.activeTabHandler('technicals', 1)}
          />
        )
      case 1:
        return (
          <TechnicalAggregate
            analysis={analysis}
            isFetching={isFetching}
          />
        )
      case 2:
        const seg_sym = decodeURIComponent(this.stock)
        seg_sym.replace(':', '_')
        return (
          <ResistanceItem
            key={seg_sym}
            seg_sym={seg_sym}
            resistance={resistance}
            user_broker_id={this.user_id}
            updateGA={() => null}
          />
        )
      case 3:
        return (
          <SampleStrategies
            samples={samples}
            segSym={this.stock}
            timeFrame={timeFrame}
            user_id={this.user_id}
            getSamples={getSamples}
            stock={stock}
            exchange={exchange}
            baseUrl={this.baseUrl}
            analysisParams={this.analysisParams}
            shouldRoute={this.route}
            kiteApp={this.kiteApp}
            strategyBacktests={(url, name, algo_uuid) => this.strategyBacktests(url, name, algo_uuid)}
            broker={this.brokerId}
          />
        )

      case 4: {
        const tags = Object.keys(popularScreens)
        const scannerView = tags.map((tag) => {
          const tagScreen = popularScreens[tag] || []
          const firstScanner = tagScreen[0] || {}
          const imageId = firstScanner.img_id || (firstScanner.tags && firstScanner.tags[0]) || 'Bearish'
          const imgClass = (imageId).toLowerCase().replace(' ', '_')
          const imageSrc = this.img_tags[imageId] || MISCELLANEOUS
          return (
            <div key={tag} className={styles.column}>
              <div className={styles.line}>
                <img src={imageSrc} className={styles[imgClass]} alt="trend" />
                <CustomText size="medium">{tag}</CustomText>
              </div>
              <div className={styles.listcontainer}>
                <Flatlist
                  data={tagScreen}
                  numberToRender={4}
                  listStyles={styles.list}
                >
                  {list => (
                    list.map(this.renderSampleBtCard)
                  )}
                </Flatlist>
              </div>
            </div>
          )
        })
        return <div className={styles.scannerContainer}>{scannerView}</div>
      }

      default:
        return null
    }
  }

  renderButton = (url) => {
    const { styles } = this.props
    let button = null
    const getDemoUrl = `${this.baseUrl}/?demo=true&rb=angel one`
    if (this.isDeviceMobile && this.route) {
      button = (
        <a
          href={url}
          target="_blank"
          onClick={this.createAlgo}
          className={styles.createBtn}
        >
          CREATE STRATEGY
        </a>
      )
    } else if (!this.isDeviceMobile) {
      button = (
        <a
          href={url}
          target="_blank"
          onClick={this.createAlgo}
          className={styles.createBtn}
        >
          SCAN NOW
        </a>
      )
    }
    return (
      <>
        <a
          href={getDemoUrl}
          target="_blank"
          onClick={this.createAlgo}
          className={styles.getDemoBtn}
        >
          GET DEMO
        </a>
        {button}
      </>
    )
  }

  // triggerBuySellGA = () => {
  //   ReactGA.event({
  //     category: 'kite_mo_buy_sell',
  //     action: 'kite_mo_buy_sell',
  //     label: escape(this.stock),
  //   })
  // }

  render() {
    const { styles, isDark, isMobile } = this.props
    const bgStyles = {
      backgroundColor: theme.bgPrimary,
    }
    const { selectedRange, activeIndex } = this.state
    const url = `${this.baseUrl}/create?stock=${encodeURIComponent(decodeURIComponent(this.stock).replace(':', '_'))}&tech_route=true${this.kiteApp ? '&tech_link=true' : ''}&${this.analysisParams}&rb=angel one`
    const logoSrc = isDark ? LogoDark : logo
    const stockIconStyle = {
      marginRight: SPACING.SPACE_8,
      display: 'inline-block',
    }
    const seg_sym = decodeURIComponent(this.stock).replace(':', '_')
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          {!isMobile ? (
            <div className={styles.left}>
              <div style={{ display: 'flex' }}>
                <StockIcon seg_sym={seg_sym} height={50} width={50} containerStyle={stockIconStyle} />
                <SegSym
                  seg_sym={seg_sym}
                  symStyles={{ fontSize: FONTS.LARGE, alignItems: 'center' }}
                  symWeight="semi_bold"
                  ellipsis
                />
              </div>
              <div className={styles.right}>
                <div className={styles.scan_section}>
                  {this.renderButton(url)}
                </div>
                <a
                  className={styles.poweredby}
                  href={`${this.baseUrl}/?${this.analysisParams}`}
                  target="_blank"
                  style={{
                    pointerEvents: this.route ? 'unset' : 'none'
                  }}
                >
                  <img src={logoSrc} alt="logo" className={styles.logo} />
                </a>
                {/* <div className={styles.scan_section}>
              {this.renderButton(url)}
            </div> */}
              </div>
            </div>
          ) : (
            <div className={styles.left}>
              <div className={styles.right}>
                <div className={styles.scan_section}>
                  {this.renderButton(url)}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <StockIcon seg_sym={seg_sym} height={30} width={30} containerStyle={stockIconStyle} />
                  <SegSym
                    seg_sym={seg_sym}
                    symStyles={{ fontSize: FONTS.LARGE, alignItems: 'center' }}
                    symWeight="semi_bold"
                    ellipsis
                  />
                </div>
                <a
                  className={styles.poweredby}
                  href={`${this.baseUrl}/?${this.analysisParams}`}
                  target="_blank"
                  style={{
                    pointerEvents: this.route ? 'unset' : 'none'
                  }}
                >
                  <img src={logoSrc} alt="logo" className={styles.logo} />
                </a>
              </div>
            </div>
          )}
          <CustomHorizontalTab
            tabs={timeFrames}
              // loading={isFetchingSamples || isFetchingAnalysis}
              // loadingText={loadingText}
            defaultActiveTab={selectedRange}
            tabChangeHandler={this.timeChangeHandler}
            style={styles.tabContainer}
            dragBtnStyles={styles.dragBtn}
            tabStyles={styles.tabBtn}
            extraStyles={isMobile ? {} : bgStyles}
            tabColor="grey"
            activeTabColor="white"
            labelProps={{
              weight: 'regular',
              size: 'small',
            }}
          />
          <TabView
            tabs={renderTypeMap}
            labelKey="title"
            defaultActiveTab={0}
            tabStyles={styles.pageTabs}
            tabChangeHandler={this.activeTabHandler}
            activeTab={activeIndex}
          />
        </div>
        {this.renderActiveTab()}
        {/* <div className={styles.copyright}>© 2019 Streak</div> */}
        <div
          className={styles.buySellDetect}
          // onMouseLeave={() => this.triggerBuySellGA()}
        />
      </div>
    )
  }
}

const stylesheet = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 1rem 1rem 1rem',
    maxWidth: '780px',
    boxSizing: 'border-box',
    backgroundColor: theme.bgPrimary,
  },
  listcontainer: {
    padding: '0rem 1rem 0 1rem',
  },
  header: {
    boxSizing: 'border-box',
    padding: '16px 10px 0',
    position: 'fixed',
    top: 0,
    zIndex: 3,
    width: '95%',
    maxWidth: '780px',
    backgroundColor: theme.bgPrimary,
    borderBottom: `1px solid ${theme.bghighlight}`,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,

    // display: 'flex',
    // flexDirection: 'row',
    // left: 0
  },
  createBtn: {
    border: '1px solid rgb(0, 19, 244)',
    padding: '6px 20px',
    fontWeight: '500',
    fontSize: '12px',
    backgroundColor: 'rgb(0, 19, 244)',
    borderRadius: '6px',
    color: 'white',
  },
  getDemoBtn: {
    border: '1px solid rgb(0, 19, 244)',
    padding: '6px 20px',
    fontWeight: '500',
    fontSize: '12px',
    color: 'rgb(0, 19, 244)',
    borderRadius: '6px',
    marginRight: '20px',
  },
  left: {
    display: 'flex',
    flex: 1,
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  right: {
    display: 'flex',
    // flexDirection: 'column',
    // width: '120px',
    alignItems: 'flex-end',
    padding: '6px 10px',
    columnGap: '20px',
  },
  poweredby: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  logo: { width: '80px' },
  scan_section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    // columnGap: 20,
    // marginBottom: 10
  },
  scan_link: {
    justifyContent: 'center',
    textDecoration: 'none',
    height: 30,
    color: '#fff',
    backgroundColor: '#253bde',
    alignItems: 'center',
    fontSize: '13px',
    display: 'flex',
    padding: '0px 16px',
    borderRadius: '25px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    boxShadow: '0px 3px 6px 1px rgba(37, 59, 222, 0.4)',
    '&:hover': { boxShadow: '0px 3px 9px 1px rgba(37, 59, 222, 0.5)' },
  },
  backtest_link: {
    justifyContent: 'center',
    textDecoration: 'none',
    width: '100px',
    border: '1px solid #253bde',
    color: '#253bde',
    backgroundColor: theme.bgPrimary,
    alignItems: 'center',
    fontSize: '0.75rem',
    display: 'flex',
    padding: '5px 10px',
    borderRadius: '2px',
    fontWeight: 400,
    maxWidth: '120px',
    '&:hover': { backgroundColor: '#253bde', color: '#fff' },
  },
  get_demo_btn: {
    borderRadius: 25,
    marginRight: 10,
    boxShadow: '3px 2px 3px 1px rgb(37 59 222 / 40%)',
    padding: '5px 0px',
  },
  stock: {
    fontSize: '1.25rem',
    paddingLeft: '6px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.textDark
  },
  exchange: {
    marginLeft: '0.5rem',
    marginTop: '4px',
    fontSize: '1rem !important'
  },
  time_ranges: {
    padding: '0.5rem 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  timeRange: {
    backgroundColor: theme.bghighlight,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '0.75rem',
    color: theme.textDark_2,
    borderRadius: '20px',
    marginRight: '0.45rem',
    padding: '4px 10px',
    '&:hover': { backgroundColor: theme.hover },
  },
  activeTime: { color: theme.activeText, backgroundColor: theme.bghighlight },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: '20px',
    minWidth: '47%',
    '&:nth-child(2)': { marginRight: 0 },
  },
  title: { fontSize: 18, color: theme.textDark_2 },
  scannerContainer: {
    paddingTop: '11.25rem',
    // height: 'calc(100vh - 10px)',
    // overflow: 'auto',
    position: 'relative',
    overflowX: 'hidden',
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1rem',
    flexDirection: 'row',
    borderBottom: '1px solid #e2e2e2'
  },
  range_breakout: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(4, 207, 147, 0.2)',
    padding: '5px',
    borderRadius: '50%'
  },
  intraday_bearish: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(255, 59, 80, 0.2)',
    padding: '5px',
    borderRadius: '50%'
  },
  intraday_bullish: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(4, 207, 147, 0.2)',
    padding: '5px',
    borderRadius: '50%'
  },
  crossover: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(9, 106, 211, 0.2)',
    padding: '5px',
    borderRadius: '50%'
  },
  bullish: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(4, 207, 147, 0.2)',
    padding: '5px',
    borderRadius: '50%'
  },
  bearish: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(255, 59, 80, 0.2)',
    padding: '5px',
    borderRadius: '50%'
  },
  top_gainers: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(4, 207, 147, 0.2)',
    padding: '5px',
    borderRadius: '50%'
  },
  top_losers: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(255, 59, 80, 0.2)',
    padding: 5,
    borderRadius: '50%'
  },
  candle_patterns: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(4, 207, 147, 0.2)',
    padding: '5px',
    borderRadius: '60%'
  },
  webinar_scanners: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(255, 59, 80, 0.2)',
    padding: 5,
    borderRadius: '60%'
  },
  breakout: {
    width: '36px',
    marginRight: '1rem',
    boxShadow: '0px 6px 9px 0.2px rgba(255, 59, 80, 0.2)',
    padding: 5,
    borderRadius: '60%'
  },
  create_button: {
    width: '160px',
    minHeight: '0 !important',
    fontSize: '14px',
    padding: '5px',
    maxHeight: '30px'
  },
  buySellDetect: {
    height: '6px',
    width: '150px',
    position: 'fixed',
    right: '105px',
    bottom: 0
  },
  cardContainer: {
    margin: `${SPACING.SPACE_24} 0`,
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  matches: {
    display: 'flex',
    columnGap: '11px',
    fontSize: 12,
    marginBottom: 16,
  },
  '@media screen and (max-width: 600px)': {
    container: { flex: 1, maxWidth: '600px', padding: '0 0 1rem' },
    header: {
      padding: '12px 10px 0',
      position: 'sticky',
      width: '100%',
      gap: '0px',
      // flexDirection: 'column-reverse'
    },
    right: {
      flexDirection: 'row',
      width: 'auto',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      columnGap: 0,
      padding: `${SPACING.SPACE_16} 0px 0px`,
    },
    scan_section: {
      // columnGap: 13,
    },
    left: {
      flexDirection: 'column-reverse',
      paddingBottom: '20px',
    },
    // stock: { display: 'none' },
    poweredby: { marginBottom: 4 },
    timeRange: { width: '48px', marginRight: 0, marginBottom: '0.75rem' },
    backtest_link: { borderRadius: '25px', width: '150px' },
    time_ranges: { flexWrap: 'wrap-reverse' },
    scannerContainer: {
      marginTop: '15px',
      padding: '0px 20px',
      // height: 'calc(100vh - 200px)',
    },
    column: { margin: 0 },
    // line: { paddingLeft: '1rem' },
    buySellDetect: {
      right: 'unset',
      width: '50%',
      bottom: 'unset',
      top: 0,
      left: 0,
      zIndex: 99
    }
  },
  deployBtn: {
    // padding: '0px 20px !important',
  },
  shareStockBtn: {
    marginLeft: `${SPACING.SPACE_10}`,
    padding: '6px 16px 6px 26px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    columnGap: '7px',
    '& > svg': {
      marginRight: '0 !important',
    },
    '&:disabled': {
      backgroundColor: 'blue',
      opacity: 0.9,
    },
  },
  shareStockBtnText: {
    fontSize: '14px',
  },
  dragBtn: {
    borderRadius: 8,
    backgroundColor: COLORS.BLUE,
  },
  tabContainer: {
    padding: 0, // `0 ${SPACING.SPACE_10}`,
    backgroundColor: 'transparent',
    borderRadius: 12,
    background: 'white',
    boxShadow: '0px 7px 20px #162B7412',
    opacity: 1,
  },
  tabBtn: {
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_5}`,
  },
  pageTabs: {
    padding: `${SPACING.SPACE_2} 3px`,
    marginRight: SPACING.SPACE_10,
    marginLeft: 0,
  },
  '@media screen and (max-width: 330px)': {
    tabContainer: {
      display: 'block',
    },
    pageTabs: {
      padding: `${SPACING.SPACE_2} ${SPACING.SPACE_2}`,
    },
    listcontainer: {
      padding: '0rem 0rem 0 0rem',
    },
  }
}

export default withTheme(stylesheet)(Info)
