/* eslint-disable react/no-deprecated */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import classnames from 'classnames'
import debounce from 'lodash/debounce'

// import PlaceHolderLoader from '../../UI/PlaceHolderLoader'
import CustomText from '../../../UI/CustomText'
import ResistanceGraph from '../../../UI/ResistanceGraph'
// import TechnicalsItemLoader from '../../UI/Loaders/TechnicalsItemLoader'
import Icon from '../../../UI/Icon'
import CustomTooltip from '../../../UI/CustomTooltip'

import {
  fetchResistance,
  // clearResistance
} from '../../../containers/Info/actions'
import {
  SPACING, theme, ICONS, FONTWEIGHT,
} from '../../../Theme'
import { withTheme } from '../../../Theme/ThemeProvider'

class ResistanceItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      showAggregate: false,
    }
    // this.fetchResistance()
  }

  componentWillMount = () => {
    const { resistance } = this.props
    if(Object.keys(resistance).length === 0) {
      this.fetchResistance()
    }
  }

  componentDidUpdate(prevProps) {
    const { timeFrame, seg_sym } = this.props
    if (!isEqual(prevProps.timeFrame, timeFrame)) {
      this.fetchResistance()
    }
    if (prevProps.seg_sym !== seg_sym && seg_sym) {
      this.fetchResistance()
    }
  }

  componentWillUnmount() {
    // const { clearResistanceData } = this.props
    // clearResistanceData()
  }

  fetchResistance = () => {
    const { seg_sym, timeFrame, user_broker_id } = this.props
    let user_id = 'ABCD12'
    const { getResistance } = this.props
    if(user_broker_id) {
      user_id = user_broker_id
    }
    const params = {
      time_frame: timeFrame || 'hour',
      stocks: [seg_sym],
      user_broker_id: user_id,
    }
    // time frame changed or multi stock fetch (page change)
    getResistance(params, { 'content-type': 'application/json' })
  }

  renderInfoCell = (label, value) => {
    const { styles } = this.props
    return (
      <div className={styles.infoCell}>
        <CustomText>{value}</CustomText>
        <CustomText size="small" weight="regular" color="textSecondary">{label}</CustomText>
      </div>
    )
  }

  renderPopup = () => {
    const { styles } = this.props
    return (
      <div className={styles.popupContainer}>
        <CustomText>
          <span style={{ fontWeight: FONTWEIGHT.MEDIUM }}>Support:</span>
          {' Support prevents the price from falling further. It is a price point on the chart where the trader expects maximum demand (in terms of buying) coming into the stock/index. Whenever the price falls to the support line, it is likely to bounce back. Resistance is something that stops the price from rising further. The resistance level is a price point on the chart where traders expect maximum supply (in terms of selling) for the stock/index. The resistance level is always above the current market price.'}
        </CustomText>
      </div>
    )
  }

  updateGA = () => {
    const { updateGA, gaCategory } = this.props
    updateGA('Info', 'Technicals Details', 1, gaCategory)
  }

  updateAnalytics = debounce(() => this.updateGA(), 600)

  render() {
    const {
      seg_sym,
      // analysis,
      isFetchingResistance,
      resistanceError,
      containerStyles = {},
      isDark,
      // ltp,
      styles,
      resistance = {},
    } = this.props
    const data = resistance[seg_sym.toUpperCase()]
    let renderView = null
    if (isFetchingResistance && !data) {
      // renderView = <TechnicalsItemLoader styles={styles} />
    } else if (data && data.noData) {
      renderView = <CustomText className={styles.noDataText}>No data available</CustomText>
    } else if (resistanceError) {
      renderView = <CustomText className={styles.errorText}>Not able to fetch data</CustomText>
    } else if (data) {
      const {
        s3 = 0.0,
        s2 = 0.0,
        s1 = 0.0,
        // pp = 0.0,
        r1 = 0.0,
        r2 = 0.0,
        r3 = 0.0,
        close = 0,
      } = data
      //   const leftPosition = Math.abs((pct * 50) + 50)
      renderView = (
        <>
          <div className={styles.graphContainer}>
            <ResistanceGraph
              isDark={isDark}
              analysis={data}
              ltp={close || 0}
              showLabels
            />
          </div>
          <div className={styles.infoRow}>
            {this.renderInfoCell('S1', s1.toFixed(2), isDark)}
            {this.renderInfoCell('S2', s2.toFixed(2), isDark)}
            {this.renderInfoCell('S3', s3.toFixed(2), isDark)}
            {this.renderInfoCell('R1', r1.toFixed(2), isDark)}
            {this.renderInfoCell('R2', r2.toFixed(2), isDark)}
            {this.renderInfoCell('R3', r3.toFixed(2), isDark)}
          </div>
        </>
      )
    }
    return (
      <div className={classnames(styles.container, containerStyles)}>
        {isFetchingResistance ? (
          // <PlaceHolderLoader className={styles.loader} />
          <div />
        ) : (
          <div className={styles.header}>
            <CustomText weight="semi_bold">Support & Resistance</CustomText>
            <CustomTooltip
              show
              tooltipProps={{ }}
              contentRenderer={this.renderPopup}
              placement="left"
              tooltipContainerStyles={styles.tooltipContainer}
              interactive
            >
              <div className={styles.info} onMouseEnter={this.updateAnalytics}>
                <Icon
                  name={ICONS.INFO}
                  className={styles.infoIcon}
                  color={theme.textSecondary}
                  size={16}
                />
              </div>
            </CustomTooltip>
          </div>
        )}
        <div className={styles.content}>{renderView}</div>
      </div>
    )
  }
}

const stylesheet = ({
  container: {
    backgroundColor: theme.bgPrimary,
    borderRadius: 6,
    minHeight: 100,
    marginTop: '10rem',
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_16}`,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    '&:hover $infoIcon': {
      fill: theme.linkColor,
    },
  },
  infoIcon: {},
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  loader: {
    height: 12,
    maxWidth: 100,
  },
  content: {
    width: '80%',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  graphContainer: {
    margin: `${SPACING.SPACE_20} auto 0`,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '65%',
  },
  infoCell: {
    marginTop: `${SPACING.SPACE_10}`,
    '&:nth-child(3n + 1)': {
      width: '40%',
    },
    '&:nth-child(3n + 2)': {
      width: '40%',
    },
    '&:nth-child(3n + 3)': {
      width: '20%',
    },
  },
  infoCellLoader: {
    marginBottom: `${SPACING.SPACE_10}`,
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:nth-child(3n + 1)': {
      width: '30%',
    },
    '&:nth-child(3n + 2)': {
      width: '40%',
    },
    '&:nth-child(3n + 3)': {
      textAlign: 'right',
      width: '30%',
    },
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_16,
    flexWrap: 'wrap',
    position: 'relative',
    left: '5%',
  },
  depthBtn: {
    display: 'flex',
    marginTop: SPACING.SPACE_6,
    alignSelf: 'flex-start',
  },
  noDataText: {
    textAlign: 'center',
    padding: SPACING.SPACE_20,
  },
  errorText: {
    textAlign: 'center',
    padding: SPACING.SPACE_20,
  },
  buySellCount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `${SPACING.SPACE_20} 0 0`,
  },
  countCell: {
    textAlign: 'center',
    flex: 0.25,
  },
  tooltipContainer: {
    backgroundColor: 'transparent',
  },
  popupContainer: {
    backgroundColor: theme.bgPrimary,
    padding: SPACING.SPACE_20,
    borderRadius: 8,
    minWidth: 300,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
  },
  '@media only screen and (max-width: 600px)': {
    container: {
      flexDirection: 'column',
      marginTop: 0,
      padding: '2rem',
      // height: 'calc(100vh - 200px)',
      overflow: 'auto',
    },
    graphContainer: {
      width: '100%',
    },
    content: {
      justifyContent: 'center',
      height: 'auto',
    }
  },
})

const mapStateToProps = (state) => {
  // const { seg_sym_list = {}, ticks } = state.instruments
  // const { seg_sym } = ownProps
  // let ltp = 0
  // if(seg_sym && seg_sym_list[seg_sym] && ticks[seg_sym_list[seg_sym]]) {
  //   ({ last_price: ltp } = ticks[seg_sym_list[seg_sym]])
  // }
  return {
    ...state.info
    // analysis: state.technicals.resistanceSingle,
    // isFetchingResistance: state.technicals.isFetchingResistance,
    // resistanceError: state.technicals.resistanceError,
    // resistanceErrorMsg: state.technicals.resistanceErrorMsg,
    // user_details: state.auth.user_details,
    // ltp,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getResistance: (params, headers) => dispatch(fetchResistance(params, headers, true)),
    // clearResistanceData: () => dispatch(clearResistance(true)),
  }
}

export default
connect(mapStateToProps, mapDispatchToProps)(
  withTheme(stylesheet)(ResistanceItem),
)
